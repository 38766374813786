const $ =require('jquery');
$(document).ready(function() {
	initfb(document, 'script', 'facebook-jssdk');

	$('.custom-select').change(function(){
		option = $("option:selected", this);
		window.location.href = option.data('href');
	});

	if ($('.desc-detail .panel .panelcontent table').length) {
		if ($(window).width() < 768) {
			$('.desc-detail .panel .panelcontent table').addClass('table-responsive');
		} else {
			$('.desc-detail .panel .panelcontent table').removeClass('table-responsive');
		}
	}
	
	if ($('.icon-ico-contact').length) {
		if ($(window).width() <= 1200) {
			$('.icon-ico-contact').click(function(e){
				e.preventDefault();
				if ($(this).parent('.aside').hasClass('hovered')) {
					$(this).parent('.aside').removeClass('hovered');
				} else {
					$(this).parent('.aside').addClass('hovered');
				}
			});
		}
	}

	if ($('.icon-ico-devis').length) {
		if ($(window).width() <= 1200) {
			$('.icon-ico-devis').click(function(e){
				e.preventDefault();
				if ($(this).parent('.aside').hasClass('hovered')) {
					$(this).parent('.aside').removeClass('hovered');
				} else {
					$(this).parent('.aside').addClass('hovered');
				}
			});
		}
	}

	$(".navbar-nav > .li-caret").click(function() {
        //$(this).children('.btn-caret').next().children().toggleClass("open");
        $(this).toggleClass("opened");
    }).find($(".sub-menu > .li-caret")).click(function(){
       	return false;
    });
    $(".sub-menu > .li-caret").click(function() {
        $(this).toggleClass("open");
    });
});

function initfb(d, s, id)
{
  	var js, fjs = d.getElementsByTagName(s)[0];
  	if (d.getElementById(id)) 
  		return;
  	js = d.createElement(s); js.id = id;
  	js.src = 'https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v3.0';
  	fjs.parentNode.insertBefore(js, fjs);
}

function initMap() {
  	france = {lat: 45.119456 , lng: 4.973860};
    var map = new google.maps.Map(document.getElementById('map'), {
      	zoom: 12,
     	center: france,
      	disableDefaultUI: true,
      	zoomControl: true,
      	mapTypeId: 'roadmap',
      	styles: [
		    {
		        "featureType": "administrative",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#bb5050"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "all",
		        "stylers": [
		            {
		                "color": "#f2f2f2"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "poi.park",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#e6f3d6"
		            },
		            {
		                "visibility": "on"
		            }
		        ]
		    },
		    {
		        "featureType": "road",
		        "elementType": "all",
		        "stylers": [
		            {
		                "saturation": -100
		            },
		            {
		                "lightness": 45
		            },
		            {
		                "visibility": "simplified"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "simplified"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#f4d2c5"
		            },
		            {
		                "visibility": "simplified"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "labels.text",
		        "stylers": [
		            {
		                "color": "#4e4e4e"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#f4f4f4"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#787878"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "transit",
		        "elementType": "all",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "water",
		        "elementType": "all",
		        "stylers": [
		            {
		                "color": "#eaf6f8"
		            },
		            {
		                "visibility": "on"
		            }
		        ]
		    },
		    {
		        "featureType": "water",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#eaf6f8"
		            }
		        ]
		    }
		]
    });
    var marker = new google.maps.Marker({
        position: france,
        map: map,
        icon : base_url+'/assets/images/marker.png',
    });
}