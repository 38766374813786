jQuery(document).ready(function () {

	/* ------------------------------------------------------------------------ */
	/* Menu */
	/* ------------------------------------------------------------------------ */

	if ('ontouchstart' in window) {
		var click = 'touchstart';
	} else {
		var click = 'click';
	}

	$('div.burger').on(click, function () {
		if (!$(this).hasClass('open')) {
			openMenu();
		} else {
			closeMenu();
		}
	});

	function openMenu() {

		$('nav.menu').addClass('animate');

		$('div.burger').addClass('open');

		setTimeout(function () {
			$('div.y').hide();
			$('div.x').addClass('rotate30');
			$('div.z').addClass('rotateneg');
			$('.aside-particulier').addClass('open');
		}, 70);
	}

	function closeMenu() {

		setTimeout(function () {
			$('div.burger').removeClass('open');
			$('nav.menu').removeClass('animate');

			setTimeout(function () {
				$('div.x').removeClass('rotate30');
				$('div.z').removeClass('rotateneg');
				$('.aside-particulier').removeClass('open');
			}, 50);
			setTimeout(function () {
				$('div.y').show();
			}, 70);
		}, 100);
	}

	/* ------------------------------------------------------------------------ */
	/* Header animation */
	/* ------------------------------------------------------------------------ */

	$('.homeslider .slide').each(function () {
		var headimgsrc = $(this).children('.headimg').children('.headsrc').attr('src');
		$(this).children('.headimg').css("background-image", "url(" + headimgsrc + ")");
	});
	var limit = 900;

	var headbannersrc = $('.head-banner').children('.headimg-banner').children('.headsrc').attr('src');
	$('.head-banner').children('.headimg-banner').css("background-image", "url(" + headbannersrc + ")");
	var limitBanner = 600;

	$(window).on('scroll', function () {
		scrollPosition = $(this).scrollTop();
		if (scrollPosition <= limit) {
			$('.headimg').css({
				'opacity': (1 - scrollPosition / limit),
				'background-position-y': "calc(" + (0 + scrollPosition) + "px + -" + (10 + scrollPosition / 20) + "px)"
			});
		}
		if (scrollPosition <= limitBanner) {
			$('.headimg-banner').css({
				'opacity': (1 - scrollPosition / limitBanner),
				'background-position-y': "calc(" + (0 + scrollPosition) + "px + -" + (10 + scrollPosition / 10) + "px)"
			});
		}

		if (scrollPosition > 250) {
			$('.top').addClass('scrolled');
		} else {
			$('.top').removeClass('scrolled');
		}

	});


	/* ------------------------------------------------------------------------ */
	/* Header Scroldown */
	/* ------------------------------------------------------------------------ */

	$('a[href^="#"]').click(function () {
		var the_id = $(this).attr("href");
		if (the_id === '#') {
			return;
		}
		$('html, body').animate({
			scrollTop: $(the_id).offset().top - 50
		}, 'slow');
		return false;
	});

	/* ------------------------------------------------------------------------ */
	/* Slider HEADER homepage */
	/* ------------------------------------------------------------------------ */

	$('.homeslider').slick({
		infinite: true,
		speed: 1000,
		adaptiveHeight: true,
		dots: true,
		arrows: false,
		draggable: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnHover: false,
		pauseOnFocus: false,
		customPaging: function (slider, i) {
			var slideNumber = (i + 1),
				totalSlides = slider.slideCount;
			return '<button type="button" role="tab" class="dot" aria-label="' + slideNumber + ' of ' + totalSlides + '"></button>';
		},
	});

	/* ------------------------------------------------------------------------ */
	/* Slider GAMMES homepage */
	/* ------------------------------------------------------------------------ */

	$('.gammes-slider').slick({
		speed: 300,
		/*adaptiveHeight: true,*/
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
	});
	$('.avis').slick({
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		variableWidth: true,
	  });

	/* ------------------------------------------------------------------------ */
	/* Slider FORMATION homepage */
	/* ------------------------------------------------------------------------ */

	$('.formation-slider').slick({
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 2,
					autoplay: true,
					autoplaySpeed: 2000,
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					slidesToShow: 1,
				}
			}
		]
	});

	/* ------------------------------------------------------------------------ */
	/* Slider intra gamme */
	/* ------------------------------------------------------------------------ */

	$('.slider-intra-gamme').slick({
		speed: 300,
		autoplay: true,
		autoplaySpeed: 6000,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
	});

	/* ------------------------------------------------------------------------ */
	/* Slider historique */
	/* ------------------------------------------------------------------------ */

	$('.historique-slider').slick({
		arrows: false,
		dots: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		focusOnSelect: true,
		//infinite: false,
		customPaging: function (slider, i) {
			var slideNumber = (i + 1),
				totalSlides = slider.slideCount;
			return '<button type="button" role="tab" class="dot" aria-label="' + slideNumber + ' of ' + totalSlides + '"></button>';
		},
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					centerMode: false,
					slidesToShow: 1,
					autoplay: true,
				}
			}
		]
	});

	/* ------------------------------------------------------------------------ */
	/* Slider gallerie image fiche produit */
	/* ------------------------------------------------------------------------ */

	$('.slider-product-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-product-nav'
	});
	$('.slider-product-nav').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.slider-product-for',
		focusOnSelect: true,
		infinite: false,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				}
			},

			{
				breakpoint: 576,
				settings: {
					slidesToShow: 3,
					arrows: false,
					autoplay: true,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					arrows: false,
					autoplay: true,
				}
			}
		]
	});

	/* ------------------------------------------------------------------------ */
	/* Accordeon fiche produit */
	/* ------------------------------------------------------------------------ */


	$('.accordion').on(click, function () {
		$(this).toggleClass('active');
		var panelheight = $(this).next().prop("scrollHeight");
		if ($(this).hasClass('active')) {
			$(this).next().css({
				"max-height": panelheight + "px"
			});
		} else {
			$(this).next().css({
				"max-height": "0px"
			});
		}
	});

//Display sous menu in mobile
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		$('header.top nav > ul > li > a').on('click', function (e) {

			if ($(this).next().html() !== "") {
				e.preventDefault()
			}

			if ($(this).hasClass('active')) {
				$(this).next().css('display', 'none ');
				$(this).removeClass('active')
			} else {
				$(this).next().attr('style', 'display: block !important');
				$(this).css('text-align', 'center')
				$(this).addClass('active')
			}

		})
	}


}); //end jquery


// GOOGLE MAP